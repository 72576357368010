:root {
  --primary-50: #e9f3fa;
  --primary-100: #c9e1f2;
  --primary-200: #a5cee9;
  --primary-300: #81bae0;
  --primary-400: #66abda;
  --primary-500: #4b9cd3;
  --primary-600: #4494ce;
  --primary-700: #3b8ac8;
  --primary-800: #3380c2;
  --primary-900: #236eb7;
  --primary-A100: #f3f9ff;
  --primary-A200: #c0deff;
  --primary-A400: #8dc4ff;
  --primary-A700: #74b7ff;
  --accent-50: #fef3e2;
  --accent-100: #fce2b6;
  --accent-200: #facf85;
  --accent-300: #f8bb54;
  --accent-400: #f7ad30;
  --accent-500: #f59e0b;
  --accent-600: #f4960a;
  --accent-700: #f28c08;
  --accent-800: #f08206;
  --accent-900: #ee7003;
  --accent-A100: #ffffff;
  --accent-A200: #ffeee1;
  --accent-A400: #ffd0ae;
  --accent-A700: #ffc195;
  --warn-50: #fde9e9;
  --warn-100: #fac7c7;
  --warn-200: #f7a2a2;
  --warn-300: #f47c7c;
  --warn-400: #f16060;
  --warn-500: #ef4444;
  --warn-600: #ed3e3e;
  --warn-700: #eb3535;
  --warn-800: #e82d2d;
  --warn-900: #e41f1f;
  --warn-A100: #ffffff;
  --warn-A200: #ffe7e7;
  --warn-A400: #ffb4b4;
  --warn-A700: #ff9b9b;

  --contrast-50: #000000;
  --contrast-100: #000000;
  --contrast-200: #000000;
  --contrast-300: #000000;
  --contrast-400: #000000;
  --contrast-500: #ffffff;
  --contrast-600: #ffffff;
  --contrast-700: #ffffff;
  --contrast-800: #ffffff;
  --contrast-900: #ffffff;
  --contrast-A100: #000000;
  --contrast-A200: #000000;
  --contrast-A400: #000000;
  --contrast-A700: #000000;
}
