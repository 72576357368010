/**
Divider utility css class
 */
.tix-divider {
  @apply bg-gray-500 flex-grow h-px my-auto;
}

/**
Anchor default styling
 */
a {
  @apply text-blue-600 hover:underline;
}

input:focus {
  box-shadow: none !important;
}

.ng-draggable {
  cursor: grab;
}

.ng-dragging {
  cursor: grabbing;
}
