// @tailwind base;
// @tailwind components;
// @tailwind utilities;

/* You can add global styles to this file, and also import other style files */

@import '@angular/cdk/overlay-prebuilt.css';
@import 'node_modules/angular-notifier/styles';

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'DM Sans', 'Helvetica Neue', sans-serif !important;
}

button.mat-calendar-body-cell:hover
  .mat-calendar-body-cell-content.mat-focus-indicator,
button.mat-calendar-body-cell:focus
  .mat-calendar-body-cell-content.mat-focus-indicator,
.mat-calendar-body-cell-content.mat-focus-indicator:hover,
.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  color: white !important;
}

.mat-chip-list-wrapper {
  width: 100% !important;
}

@import '~quill/dist/quill.snow.css';
