.inline-input-select .mat-input-element {
  width: calc(100% - 8.5rem) !important;
}

.inline-input-select mat-select {
  @apply border-l pl-4 border-gray-300 absolute  right-0 top-[-8px] w-32 flex items-center justify-end h-[58px];
}

mat-form-field.inline-input-select,
.inline-input-select mat-form-field {
  @apply relative;
}
