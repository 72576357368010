/* ----------------------------------------------------------------------------------------------------- */
/*  @ Tabs
/* ----------------------------------------------------------------------------------------------------- */
@mixin angular-material-overrides($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  .mat-elevation-z8 {
    border: 1px solid #f0f0f0;
    overflow: auto;
    margin-top: 20px;
    overflow-x: scroll;
    display: block;
    > * {
      display: block;
    }
  }
  .mat-form-field-subscript-wrapper {
    position: relative !important;
  }
  .mat-form-field-wrapper {
    padding-bottom: 5px !important;
  }
  .mat-table {
    overflow-x: scroll;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 10px;
    line-break: unset;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }
  .mat-header-row {
    background-color: #fafafa;
  }
  .mat-header-cell {
    color: rgba(0, 0, 0, 0.54);
    border-right: 1px solid #f0f0f0;
    padding: 16px;
    line-height: 1.5em;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 500;
    background-color: #fafafa;
    width: fit-content !important;
  }

  .mat-header-row {
    min-height: 56px;
  }

  .mat-cell {
    color: rgba(0, 0, 0, 0.87);
    border-right: 1px solid #f0f0f0;
    padding: 16px;
    line-height: 1.5em;
    font-size: 0.875em;
    word-break: break-all !important;
  }

  .mat-row {
    min-height: 52px;
  }

  .mat-row:nth-child(even) {
    background-color: #ecebeb;
  }

  .mat-form-field-outline-gap {
    display: none !important;
  }

  .mat-expansion-panel-content {
    font: inherit !important;
  }

  .mat-form-field-label-wrapper {
    @apply translate-y-2;
  }
  .mat-select-multiple {
    @apply border-0 p-0;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    @apply text-gray-300 focus:text-primary;
    // opacity: 1!important;
  }
  .mat-form-field-flex {
    max-height: 63px !important;
  }
  mat-form-field {
    @apply w-full;
  }

  tix-form-field {
    .mat-form-field-wrapper {
      @apply col-span-3 sm:col-span-2;
    }

    .mat-form-field-label {
      @apply block text-xs font-light text-gray-500 px-3 pt-1;
    }

    .mat-form-field-flex {
      @apply rounded-md border border-gray-300
        transition-all duration-500
        focus-within:ring-blue-500 focus-within:border-blue-500;
    }

    // INPUT, PREFIX, SUFFIX STYLING
    .mat-form-field-infix {
      @apply flex rounded-md shadow-sm;

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        @apply inline-flex items-center rounded-md bg-gray-100 px-2 m-1 text-gray-500 text-sm sm:text-base;
      }

      .mat-input-element {
        @apply block flex-1 w-full px-0 text-sm sm:text-base rounded-b-md border-none focus:ring-transparent;

        &:first-child {
          @apply pl-3;
        }

        &:last-child {
          @apply pr-3;
        }

        &:-webkit-autofill {
          background: rgba(197, 197, 197, 0.5) none;
        }
      }
    }

    // SUBSCRIPT STYLING
    .mat-form-field-subscript-wrapper {
      @apply mt-0.5 mb-2 text-xs sm:text-sm min-h-[1.25rem] px-3;

      .mat-error {
        @apply block text-red-500;
      }

      .mat-form-field-hint-wrapper {
        @apply flex justify-between;

        .mat-form-field-hint-spacer {
          flex: 1 0 1em;
        }

        .mat-hint {
          @apply text-gray-500;
        }
      }
    }

    // ERROR STYLING
    .mat-form-field.mat-form-field-invalid {
      .mat-form-field-label {
        @apply text-red-500;
      }

      .mat-form-field-flex {
        @apply focus:ring-red-500 border-red-500 text-red-800;
      }

      .mat-select-append {
        @apply focus:ring-red-500 border-red-500 text-red-800
          group-focus-within:border-red-500 group-focus-within:text-red-800;
      }
    }

    .mat-form-field-appearance-outline {
      &:not(.mat-form-field-disabled) {
        .mat-form-field-flex {
          &:hover {
            .mat-form-field-outline-thick {
              opacity: 0 !important;
            }
          }
          .mat-form-field-outline-thick {
            opacity: 0 !important;
          }
        }
      }
      .mat-form-field-infix {
        padding: 0.75em 0 0.75em 0;
      }
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent;
    }
    .mat-form-field.mat-focused .mat-form-field-label {
      @apply text-gray-500;
    }
    .mat-form-field.mat-form-field-invalid .mat-form-field-label {
      @apply text-red-400;
    }
    .mat-form-field-label {
      padding-left: 0;
    }
    .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
      padding: 0.2em 1em !important;
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                 mat-dailog                                 */
  /* -------------------------------------------------------------------------- */

  .mat-dialog-container {
    border-radius: 16px !important;
  }

  /* -------------------------------------------------------------------------- */
  /*                                   scroll                                   */
  /* -------------------------------------------------------------------------- */

  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: map-get($primary, 300);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: map-get($primary, 500);
  }
}
