/** Get the value for the specified keys or use the default value if not found. **/
@function mapGetOrDefault($map, $keys, $defaultValue) {
  $values: $map;
  @each $key in $keys {
    @if map-has-key($values, $key) {
      $values: map-get($values, $key);
    } @else {
      @return $defaultValue;
    }
  }
  @return $values;
}
